import React, { type ComponentProps } from 'react';
import Logo from 'bb/common/Logo';
import type { BookLightResponse } from 'bb/discovery/types';
import css from './bookCard.module.scss';

export type BookCardImageProps = {
    book: BookLightResponse;
} & ComponentProps<'img'>;

export const BookCardImage = (props: BookCardImageProps) => {
    const { book, ...restProps } = props;
    const { image, title } = book;

    if (!image?.includes('bb-images.akamaized'))
        return <Logo className={css.image} variant="white" />;

    const [baseUrl] = image.split('?');

    return (
        // eslint-disable-next-line @next/next/no-img-element
        <img
            {...restProps}
            className={css.image}
            alt=""
            src={`${baseUrl}?w=${200}`}
            title={title}
            loading="lazy"
        />
    );
};
