import React, { type ComponentProps } from 'react';
import classNames from 'classnames';
import css from './bookCard.module.scss';

export type BookCardBadgeProps = {
    variant: 'primary' | 'secondary' | 'topList';
} & ComponentProps<'div'>;

export const BookCardBadge = ({
    variant,
    children,
    className,
    ...restProps
}: BookCardBadgeProps) => (
    <div
        {...restProps}
        className={classNames(css.badge, css[variant], className)}
    >
        {children}
    </div>
);
