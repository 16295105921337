/* eslint-disable complexity */
import React from 'react';
import type { BookCardCommonProps } from 'bb/discovery/components/BookCardV2/BookCard';
import { getContrastColor } from 'bb/style/utils';
import { Box, type BoxProps } from 'bb/ui/Box';
import { Flex } from 'bb/ui/Flex/Flex';
import { Gap } from 'bb/ui/Gap';
import { ImageIcon, type ImageIconProps } from 'bb/ui/Icons/ImageIcon';
import { StarIcon } from 'bb/ui/Icons/StarIcon';
import { Typography } from 'bb/ui/Typography';

export type BookCardHeaderProps = Required<BookCardCommonProps> &
    Omit<BoxProps, 'padding' | 'border'>;

export const BookCardHeader = (props: BookCardHeaderProps) => {
    const {
        bgColor = 'primary-black',
        book,
        ownedAudiobook,
        ownedEbook,
        showSeriesPartNumber,
        ...restProps
    } = props;
    const contrastColor = getContrastColor(bgColor);

    const isLightScheme = contrastColor === 'primary-black';
    const isAudioDrama = book.contenttypetags?.includes('audiodrama');

    const left = (
        <Gap direction="row" spacing={2}>
            {book.grade > 0 && (
                <>
                    <StarIcon color={contrastColor} size="small" />
                    <Typography color={contrastColor} variant="body2">
                        {book.grade}
                    </Typography>
                </>
            )}
        </Gap>
    );

    const imageIconProps: Partial<ImageIconProps> = {
        invert: !isLightScheme
    };

    const right = (
        <Gap direction="row" spacing={2}>
            {isAudioDrama && (
                <ImageIcon svg="audiodrama" size="small" {...imageIconProps} />
            )}
            {(book.audio || book.audiobookisbn) &&
                (book.audiobooksinglesale && !ownedAudiobook ? (
                    <ImageIcon
                        svg={
                            isLightScheme
                                ? 'locked-audiobook-black'
                                : 'locked-audiobook'
                        }
                        size="small"
                    />
                ) : (
                    <ImageIcon
                        svg="headphones"
                        size="small"
                        {...imageIconProps}
                    />
                ))}
            {(book.ebook || book.ebookisbn) &&
                (book.ebooksinglesale && !ownedEbook ? (
                    <ImageIcon
                        svg={
                            isLightScheme
                                ? 'locked-ebook-black'
                                : 'locked-ebook'
                        }
                        size="small"
                    />
                ) : (
                    <ImageIcon svg="glasses" size="small" {...imageIconProps} />
                ))}

            {book.series && (
                <ImageIcon svg="series" size="small" {...imageIconProps} />
            )}
            {showSeriesPartNumber && book.series && (
                <Typography>{book.series.partnumber}</Typography>
            )}
        </Gap>
    );

    return (
        <Box {...restProps} bgColor={bgColor} padding={[3, 4]}>
            <Flex justifyContent="spaceBetween">
                {left}
                {right}
            </Flex>
        </Box>
    );
};
