import React, { type ElementType, type JSX } from 'react';
import classNames from 'classnames';
import type { BookLightResponse } from 'bb/discovery/types';
import { getContrastColor, makeCSSVariableFromColor } from 'bb/style/utils';
import { Badge } from 'bb/ui/Badge/Badge';
import { NumberBadge } from 'bb/ui/Badge/NumberBadge';
import { Box, type BoxProps } from 'bb/ui/Box';
import type { SvgName } from 'bb/ui/Icons/ImageIcon';
import { Typography, type TypographyProps } from 'bb/ui/Typography';
import css from './bookCard.module.scss';
import { BookCardBadge } from './BookCardBadge';
import { BookCardHeader } from './BookCardHeader';
import { BookCardImage } from './BookCardImage';

export type BookCardCommonProps = {
    book: BookLightResponse;
    /**
     * If `true`, an indicator of the series part number is shown.
     *
     * @defaultValue `false`
     */
    showSeriesPartNumber?: boolean;
    /**
     * If `true`, the audio book is marked as owned.
     *
     * @defaultValue `false`
     */
    ownedAudiobook?: boolean;
    /**
     * If `true`, the e-book is marked as owned.
     *
     * @defaultValue `false`
     */
    ownedEbook?: boolean;
};

export type BookCardProps<TElementType extends ElementType = 'div'> = {
    /**
     * If the bookList `renderType` equals `NumberedList`
     * a number will be passed to the card.
     *
     * If passed, no badges are shown.
     */
    bookTopListNumber?: number;
} & BookCardCommonProps &
    Omit<BoxProps<TElementType>, 'padding' | 'border'>;

export const BookCard = ((props: BookCardProps<'div'>) => {
    const {
        book,
        bgColor = 'gray-06',
        style,
        className,
        as = 'div',
        bookTopListNumber,
        ownedEbook = false,
        ownedAudiobook = false,
        showSeriesPartNumber = false,
        ...restProps
    } = props;
    const contrastColor = getContrastColor(bgColor);
    const isLightScheme = contrastColor === 'primary-black';
    const isPurpleBgColor = bgColor === 'primary-purple';

    const footerTextProps: TypographyProps<'p'> = {
        as: 'p',
        className: css.ellipsis,
        color: contrastColor
    };

    const [primaryBadge, secondaryBadge] = book.topbadges ?? [];

    return (
        <Box
            {...restProps}
            as={as}
            className={classNames(css.root, className)}
            bgColor={bgColor}
            style={{
                ...style,
                ...makeCSSVariableFromColor(
                    '--typography-color',
                    contrastColor
                ),
                ...makeCSSVariableFromColor(
                    '--hover-bg-color',
                    isPurpleBgColor ? 'primary-black' : 'primary-purple'
                ),
                ...makeCSSVariableFromColor(
                    '--hover-text-color',
                    isPurpleBgColor ? 'primary-white' : 'primary-black'
                )
            }}
        >
            <BookCardHeader
                ownedAudiobook={ownedAudiobook}
                ownedEbook={ownedEbook}
                showSeriesPartNumber={showSeriesPartNumber}
                book={book}
                bgColor={bgColor}
                aria-hidden
            />
            <Box
                className={css.cover}
                bgColor={
                    bgColor === 'transparent'
                        ? 'transparent'
                        : isLightScheme
                          ? 'gray-02'
                          : 'gray-04'
                }
                /**
                 * The `transparent` bgColor doesn't have a frame.
                 */
                padding={bgColor === 'transparent' ? 0 : 4}
                aria-hidden
            >
                <BookCardImage book={book} />
                {bookTopListNumber && (
                    <BookCardBadge variant="topList">
                        <NumberBadge number={bookTopListNumber} />
                    </BookCardBadge>
                )}
                {primaryBadge && !bookTopListNumber && (
                    <BookCardBadge variant="primary">
                        <Badge
                            translationKey={primaryBadge.translationKey}
                            icon={primaryBadge.icon as SvgName}
                        />
                    </BookCardBadge>
                )}
                {secondaryBadge && (
                    <BookCardBadge variant="secondary">
                        <Badge translationKey={secondaryBadge.translationKey} />
                    </BookCardBadge>
                )}
            </Box>
            <Box
                aria-hidden
                className={css.footer}
                bgColor={bgColor}
                padding={[3, 4]}
            >
                <Typography {...footerTextProps}>{book.title}</Typography>
                <Typography {...footerTextProps}>{book.author}</Typography>
            </Box>
        </Box>
    );
}) as <TElementType extends ElementType = 'div'>(
    props: BookCardProps<TElementType>
) => JSX.Element;
