import React, { useRef } from 'react';
import { Link } from 'bb/app/nav/Link/Link';
import { useRouter } from 'bb/app/router/useRouter';
import { useFocusElement } from 'bb/common/hooks/useFocusElement';
import { getDiscoveryUrlSlug } from 'bb/discovery/utils/getDiscoveryUrlSlug';
import useAllyCardTitle from 'bb/i18n/useAllyCardTitle';
import { BookCard, type BookCardProps } from './BookCard';
import css from './bookCard.module.scss';

export type BookCardLinkProps = {
    /**
     * If `true`, the link will be focused when the component mounts.
     *
     * @defaultValue `false`
     */
    focusOnMount?: boolean;
} & Omit<BookCardProps, 'as'>;

export const BookCardLink = (props: BookCardLinkProps) => {
    const {
        focusOnMount = false,
        book,
        bookTopListNumber,
        ownedAudiobook,
        ownedEbook,
        ...restProps
    } = props;

    const { routes } = useRouter();
    const linkRef = useRef<HTMLAnchorElement>(null);

    useFocusElement(focusOnMount, linkRef);
    const allyText = useAllyCardTitle(
        book,
        bookTopListNumber,
        ownedAudiobook,
        ownedEbook
    );

    return (
        <Link
            ref={linkRef}
            route={routes.book}
            className={css.bookCardLink}
            query={{
                bookId: getDiscoveryUrlSlug({
                    title: book.title,
                    id: book.id.toString()
                })
            }}
            title={allyText}
            composedComponent={(linkProps) => (
                <BookCard
                    {...linkProps}
                    {...restProps}
                    as="a"
                    ownedAudiobook={ownedAudiobook}
                    ownedEbook={ownedEbook}
                    bookTopListNumber={bookTopListNumber}
                    book={book}
                />
            )}
        ></Link>
    );
};
